import PropTypes from 'prop-types'

export const Title = function ({ children }) {
  return (
    <h1 className="text-xl font-medium text-gray-800 sm:text-2xl">
      {children}
    </h1>
  )
}

export const Text = function ({ children, light }) {
  return (
    <p className={`${light ? 'text-gray-500' : 'text-gray-800'}`}>{children}</p>
  )
}
Text.propTypes = {
  light: PropTypes.bool,
}

export const Standout = function ({ children }) {
  return <span className="font-medium text-gray-800">{children}</span>
}

export const Label = function ({ children, label = '', small = false }) {
  return (
    <label
      className={`block capitalize text-gray-700 ${
        small ? 'text-md' : 'text-lg'
      }`}
    >
      {label || children}
    </label>
  )
}
Label.propTypes = {
  label: PropTypes.string,
  small: PropTypes.bool,
}
