import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline'
import Logo from 'components/global/Logo'
import { Title } from 'components/global/Typography'
import PropTypes from 'prop-types'

const FullpageNotice = ({ type, title, message, actions }) => {
  // Define icon if we have a type
  let icon
  switch (type) {
    case 'success':
      icon = (
        <div className="mb-4 inline-block rounded-full bg-purple-200 p-2">
          <CheckCircleIcon className="mx-auto h-12 w-12 text-purple-600" />
        </div>
      )
      break
    case 'error':
      icon = (
        <div className="mb-4 inline-block rounded-full bg-red-50 p-2">
          <ExclamationCircleIcon className="mx-auto h-12 w-12 text-red-500" />
        </div>
      )
      break
  }

  return (
    <div
      id="global-notice-screen"
      className="flex flex-1 flex-col items-center justify-center space-y-8 p-8 text-center lg:space-y-12"
    >
      {/* Message */}
      <div>
        <div>
          {icon}
          <Title>{title}</Title>
        </div>
        {message && (
          <div className="mt-2">
            <p className="text-gray-500">{message}</p>
          </div>
        )}
      </div>

      {/* Actions */}
      {actions && (
        <div className="flex justify-center space-x-2">{actions}</div>
      )}

      {/* Logo */}
      <div>
        <Logo introduction center />
      </div>
    </div>
  )
}

// Define prop types
FullpageNotice.propTypes = {
  type: PropTypes.oneOf(['success', 'error']),
  title: PropTypes.string,
  message: PropTypes.string,
  actions: PropTypes.node,
}

export default FullpageNotice
