import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useRecoilValue, useRecoilState } from 'recoil'
import { LockClosedIcon } from '@heroicons/react/outline'

import {
  currentSessionState,
  currentUpdateState,
  providerNotFoundState,
} from 'atoms/Link'
import { useRequests } from 'providers/requests'
import { useAccess } from 'providers/access'
import { useAnalytics } from 'providers/analytics'
import { parseServerMessage } from 'providers/utils'
import { globalErrorState } from 'atoms/Form'

import EmbedLayout from 'components/layouts/EmbedLayout'
import Button from 'components/global/Button'
import Alert from 'components/global//Alert'
import FullpageNotice from 'components/global/FullpageNotice'
import Spinner from 'components/global/Spinner'
import ExternalLink from 'components/global/ExternalLink'
import PolicyConfirmation from 'components/access/steps/PolicyConfirmation'
import { PRIVACY_URL } from 'data/links'

import { DocumentAddIcon, PaperClipIcon } from '@heroicons/react/outline'

function DocumentUpload({
  setShowDocumentUploadScreen,
  actionRequired,
  carrier,
  searchTerm,
}) {
  // Local state
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)

  // Shared state
  const currentSession = useRecoilValue(currentSessionState)
  const currentUpdate = useRecoilValue(currentUpdateState)
  const [providerNotFound, setProviderNotFound] = useRecoilState(
    providerNotFoundState
  )
  const [, setGlobalError] = useRecoilState(globalErrorState)

  // Access requests
  const { makePostRequest, pushDocumentUpload, pushStepChange } = useRequests()

  // Access analytics
  const analytics = useAnalytics()

  // Update state
  const access = useAccess()

  useEffect(() => {
    pushStepChange('document_upload');
    return () => {
      pushStepChange(currentUpdate.step_type);
    }
  }, []);

  const handleUpload = async () => {
    setLoading(true)

    // Check if coming from login page
    if (actionRequired) {
      await access.action(
        {
          action: 'select',
          selection: currentUpdate._embedded.provider.id,
        },
        false,
        null,
        false
      )
    }

    try {
      const request = await makePostRequest({
        endpoint: `/v1/links/${currentSession.linkId}/documents`,
        contentType: 'application/pdf',
        file,
      })

      setLoading(false)

       // Successful response
      if (request.response.ok) {
        // Handle parsed file
        if (request.response.status === 200) {
          // Trigger document upload callback
          pushDocumentUpload(request.response.headers.get('X-Covie-Document'))

          // Trigger analytics event
          analytics.capture('DocumentUploadedAndParsed', { carrier, searchTerm })

          // Handle response as an update
          access.handleUpdate(request)

          // Remove global error
          setGlobalError('')
        } else { // Handle un-parsed but accepted upload
          // Trigger document upload callback
          pushDocumentUpload(request.result._links.self.href)

          // Trigger analytics event
          analytics.capture('DocumentUploaded', { carrier, searchTerm })

          // Show complete state
          setComplete(true)
        }
      } else {  
        setError(
          `Problem uploading file - ${parseServerMessage(request.result.message)}`
        )
        setShowConfirmationScreen(true)
      }
    } catch (error) {
      if (error === 'request_timeout') {
        setError(
          'Problem uploading file: the server took too long to process'
        )
      } else {
        setError(
          'Problem uploading file'
        )
      }  
      
      setShowConfirmationScreen(true)
    }   
  }

  const handleSelect = (file) => {
    if (file[0].name.split('.').pop().toLowerCase() !== 'pdf') {
      setError('Please upload a PDF policy document.')
    } else if (file.length > 1) {
      setError('Please upload only one file.')
    } else {
      setError('')
      setFile(file[0])
    }
  }

  const handleChange = () => {
    setError('')
    setFile(null)
  }

  if (showConfirmationScreen && currentSession.confirmOnUpload) {
    return (
     <PolicyConfirmation
     uploadError={error}
     setShowConfirmationScreen={setShowConfirmationScreen}
     />
    )
  }

  if (complete) {
    return (
      <EmbedLayout title="Upload Your Policy Document">
        <FullpageNotice
          type="success"
          title="Upload Completed"
          message="Your policy document has been uploaded, but could not be parsed. Click below if you want to tell us more about your insurance carrier."
        />
        <Button
          onClick={() => {
            setProviderNotFound(true)
          }}
        >
          Tell us more about your carrier
        </Button>
      </EmbedLayout>
    )
  }

  return (
    <EmbedLayout title="Upload your document">
      {error && (
        <Alert dismissible type="error">
          {error}
        </Alert>
      )}

      <div className="flex h-full w-full grow flex-col justify-center space-y-12 rounded-lg border border-dashed border-gray-200 bg-gray-50 p-4">
        {loading ? (
          <>
            <Spinner />
            <div className="space-y-1 text-center">
              <p className="text-xl">Uploading your document...</p>
              <p className="text-gray-500">this might take a minute</p>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center space-y-6">
            <ExternalLink
              href={PRIVACY_URL}
              className="external-link flex items-center space-x-2 text-gray-500 underline duration-100 hover:text-gray-700"
            >
              {({ loading }) => (
                <>
                  <span>
                    <LockClosedIcon className="h-5 w-5" />
                  </span>
                  <span>
                    {loading ? 'Opening...' : 'How do we protect your data?'}
                  </span>
                </>
              )}
            </ExternalLink>
            {/* File select */}
            <Dropzone onDrop={(acceptedFiles) => handleSelect(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="flex cursor-pointer flex-col items-center space-y-6"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />

                    <div className="flex items-center justify-center rounded-full bg-purple p-8 text-white">
                      {file ? (
                        <PaperClipIcon className="h-8 w-8" />
                      ) : (
                        <DocumentAddIcon className="h-8 w-8" />
                      )}
                    </div>

                    <div className="space-y-1 text-center">
                      <p className="text-xl">
                        {file ? file.name : 'Select your document to upload'}
                      </p>
                      <p className="text-gray-500">or drag it here</p>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>

            {/* Selected file prompts */}
            {file && (
              <div className="space-x-4 self-center">
                <Button color="white" onClick={handleChange}>
                  Change file
                </Button>
                <Button disabled={!file} onClick={handleUpload}>
                  Upload file
                </Button>
              </div>
            )}
          </div>
        )}

        {/* Cancel button */}
        <div className="flex justify-center">
          <p
            className="self-center text-gray-500 underline hover:cursor-pointer"
            onClick={() => setShowDocumentUploadScreen(false)}
          >
            Back
          </p>
        </div>
      </div>
    </EmbedLayout>
  )
}

export default DocumentUpload
