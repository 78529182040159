import PropTypes from 'prop-types'
import { useApp } from 'providers/app'
import ExternalLink from '../ExternalLink'

/**
 * Generic button wrapper used across the app
 *
 * Can be recolored and accepts a click event callback.
 * Passing a href will render an anchor tag.
 * Children simply renders whatever markup was passed within the component.
 *
 */
function Button({
  id = '',
  children,
  type = 'button',
  href,
  externalHref,
  target,
  color,
  size,
  loading = false,
  onClick = () => {},
  block = false,
  disabled = false,
  label = '',
}) {
  // Access brand
  const { primaryColor, primaryBackgroundStyle } = useApp()

  // Base class
  let baseClasses = `transition inline-flex items-center justify-center duration-200 font-medium rounded border text-center`

  // Check size
  let sizeClass
  switch (size) {
    default:
    case 'md':
      sizeClass = 'px-6 py-2'
      break
    case 'sm':
      sizeClass = 'px-3 py-2 text-sm'
      break
    case 'lg':
      sizeClass = 'px-8 py-3 text-lg'
      break
  }

  // Check color
  let colorClass, brandStyles
  switch (color) {
    default:
    case 'primary':
    case 'purple':
      colorClass =
        'bg-purple text-white border-purple hover:bg-purple-light hover:border-purple-light'
      brandStyles = primaryColor && {
        ...primaryBackgroundStyle,
        '&:hover': {
          backgroundOpacity: '0.75',
        },
      }
      break
    case 'orange':
      colorClass =
        'bg-orange text-white border-orange hover:bg-orange-light hover:border-orange-light'
      break
    case 'yellow':
      colorClass =
        'bg-yellow text-white border-yellow hover:bg-yellow-light hover:border-yellow-light'
      break
    case 'blue':
      colorClass =
        'bg-blue text-white border-blue hover:bg-blue-light hover:border-blue-light'
      break
    case 'clear':
      colorClass =
        'bg-black bg-opacity-0 text-white border-transparent hover:bg-opacity-10'
      break
    case 'black':
      colorClass =
        'bg-black text-white border-black hover:bg-gray-900 hover:border-gray-900'
      break
    case 'gray':
      colorClass =
        'bg-gray-700 text-white border-gray-700 hover:bg-gray-800 hover:border-gray-800'
      break
    case 'light-gray':
      colorClass =
        'bg-gray-100 text-gray-700 border-gray-100 hover:bg-gray-200 hover:border-gray-200'
      break
    case 'white':
      colorClass =
        'bg-white text-gray-800 border-gray-500 hover:bg-gray-50 hover:border-gray-300'
      break
  }

  // Other classes
  const stateClass = loading || disabled ? 'cursor-not-allowed' : ''
  const disabledClass = disabled ? 'opacity-50' : ''
  const widthClass = block ? 'block w-full' : ''

  // Compile class
  const finalClass = `${baseClasses} ${stateClass} ${disabledClass} ${widthClass} ${colorClass} ${sizeClass}`

  // Render internal link
  if (href) {
    return (
      <a
        id={id}
        href={href}
        target={target}
        className={finalClass}
        style={brandStyles}
      >
        {children}
      </a>
    )
  }

  // Render external link
  if (externalHref) {
    return (
      <ExternalLink
        id={id}
        href={externalHref}
        className={`external-link ${finalClass}`}
        style={brandStyles}
      >
        {({ loading }) =>
          loading ? (
            <svg
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              id="loading-image"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <span>{label || children}</span>
          )
        }
      </ExternalLink>
    )
  }

  // Render button
  return (
    <button
      id={id}
      type={type}
      className={finalClass}
      onClick={onClick}
      disabled={loading || disabled}
      style={brandStyles}
    >
      {loading ? (
        <svg
          className="h-5 w-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          id="loading-image"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <span>{label || children}</span>
      )}
    </button>
  )
}

// Define prop types
Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  href: PropTypes.string,
  externalHref: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'purple',
    'clear',
    'black',
    'gray',
    'light-gray',
    'white',
    'orange',
    'yellow',
    'blue',
    'semi-transparent',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  loading: PropTypes.bool,
  target: PropTypes.string,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Button
