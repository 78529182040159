import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  currentSessionState,
  currentUpdateState,
  apiBaseState,
  disclosureCompletedState,
} from 'atoms/Link'
import {
  globalErrorState,
  globalLoadingState,
  localErrorState,
} from 'atoms/Form'
import { destroyCookie, parseCookies, setCookie } from 'nookies'

import { useAnalytics } from './analytics'
import { useRequests } from './requests'
import { useApp } from './app'
import { generateSessionID } from './utils'
import { parseServerMessage } from 'providers/utils'

const AccessContext = createContext()

export const useAccess = () => {
  return useContext(AccessContext)
}

export const AccessProvider = ({ children }) => {
  const {
    makePostRequest,
    pushStepChange,
    pushIneligibleLink,
    pushLinkError,
    pushSuccess,
    pushInit,
    throwGlobalError,
    getBearerToken,
  } = useRequests()

  // Access analytics
  const analytics = useAnalytics()

  const { setBrand } = useApp()

  // Access router
  const router = useRouter()

  // Local state
  const [initialized, setInitialized] = useState(false)
  const [triggerStepChange, setTriggerStepChange] = useState(true)

  // Shared state
  const [currentUpdate, setCurrentUpdate] = useRecoilState(currentUpdateState)
  const [currentSession, setCurrentSession] =
    useRecoilState(currentSessionState)
  const [globalLoading, setGlobalLoading] = useRecoilState(globalLoadingState)
  const [globalError, setGlobalError] = useRecoilState(globalErrorState)
  const apiBase = useRecoilValue(apiBaseState)
  const hasCompletedDisclosure = useRecoilValue(disclosureCompletedState)
  const [, setLocalError] = useRecoilState(localErrorState)
  const loginAttemptsRef = useRef(0)

  // Watch for initialized change
  useEffect(() => {
    if (initialized) pushInit(currentSession.linkId)
  }, [initialized])

  // Watch for step change
  useEffect(() => {
    if (currentUpdate?.step_type && triggerStepChange) {

      // Clear login attempts when visting provider selection 
      if(currentUpdate.step_type == "provider_selection") {
        loginAttemptsRef.current = 0
      }

      // Disclosure override
      if (currentUpdate.show_disclosure === true && !hasCompletedDisclosure) {
        pushStepChange('disclosure')
      }

      // Push step change
      else {
        pushStepChange(currentUpdate.step_type)
      }

      // Reset scroll
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    // Update stateId in session
    if (currentUpdate?.state_id)
      setCookie(null, 'stateId', currentUpdate.state_id, {
        maxAge: 30 * 60,
        path: '/',
      }) // We don't care if stateId exists or not, as if it doesn't we want to clear it
  }, [currentUpdate])

  // Watch for session change
  useEffect(() => {
    if (currentSession) {
      // If we have a link, set initialized to true
      if (currentSession.linkId && currentSession.sessionId && !initialized)
        setInitialized(true)

      // If we have a recover flag, call the update action
      if (currentSession.recover)
        action({ action: 'recall', state_id: currentSession.stateId })

      // If we have the reset flag, clear the session and request a new link
      if (currentSession.reset) initialize()
    }
  }, [currentSession])

  // Clear localError on step change
  useEffect(() => {
    setLocalError(null)
  }, [currentUpdate?.step_type])

  /**
   * Initialize the link
   */
  const initialize = async () => {
    try {
      // Get integration key and link ID from context
      const { integrationKey, linkId, applicationEnvironment } = currentSession

      // If we don't have an integration key, fail
      if (!integrationKey) {
        throwGlobalError('Invalid integration key', 'INVALID_INTEGRATION_KEY')
        return
      }

      // New link
      if (!linkId) {
        await runInitRequest(`/v1/links`)
        return
      }

      // Check if we have an existing session for the same link and attempt to recover
      const cookies = parseCookies()
      if (cookies.sessionId && cookies.stateId && linkId === cookies.linkId) {
        // Recall existing session
        setCurrentSession({
          linkId,
          applicationEnvironment,
          integrationKey,
          sessionId: cookies.sessionId,
          stateId: cookies.stateId,
          recover: true,
        })

        return
      }

      // Interacting with a link ID we don't have a session for; reset, then grab details for that link
      reset()
      await runInitRequest(`/v1/links/${linkId}/updates`)
    } catch (error) {
      console.log(error)

      // TODO: Add more specific error messages
      throwGlobalError('Something went wrong.', 'INIT_FAILED')
    }
  }

  const runInitRequest = async (url) => {
    // Generate session token
    const sessionId = generateSessionID()

    // Get info from initial session
    const { integrationKey, policyRequirements, broadcastMissingProvider } =
      currentSession

    // Get height/width
    const { height, width, linkMetadata } = router.query
    const linkMetadataObject = linkMetadata
      ? JSON.parse(
          decodeURIComponent(
            typeof linkMetadata === 'string' ? linkMetadata : linkMetadata[0]
          )
        )
      : {}

    var referer = '' // TODO get this working in a way that doesn't throw a SOP exception

    // Prepare request headers
    const headers = {
      'Parent-Url': referer,
      'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    // Prepare request params
    const params = {
      policy_requirements: policyRequirements,
    }

    if (url === '/v1/links') {
      /*
      Not for metadata update implementation
      Backend class UpdateLink::isBlankRequest will need to be updated to ignore the metadata param
      Otherwise you will get a LinkSessionExpired error: "The link session you requested is no longer valid."
      This is due to blank requests being the trigger of new session creation.
       */
      params.metadata = linkMetadataObject
    }

    // Make request
    const { response, result } = await makePostRequest({
      endpoint: url,
      payload: params,
      token: `${integrationKey}:${sessionId}`,
      headers,
    })

    // Check for successful response
    if (response.status !== 200) {
      throwGlobalError(result || 'Something went wrong.', 'INIT_FAILED')
      return
    }

    // Get brand from server
    if (result.brand) {
      setBrand(result.brand)
    }

    // Set up analytics
    analytics.init(
      result.integration_id,
      result.application_environment,
      height,
      width,
      result.id
    )

    // Store link ID from init request
    const linkId = result.id

    // Set cookie data
    setCookie(null, 'linkId', linkId, { maxAge: 30 * 60, path: '/' })
    setCookie(null, 'sessionId', sessionId, { maxAge: 30 * 60, path: '/' })

    // Store session data
    setCurrentSession({
      linkId,
      sessionId,
      integrationKey,
      broadcastMissingProvider,
      showDocumentUpload: result.show_document_upload,
      confirmOnUpload: result.confirm_on_upload,
      showDisclosure: result.show_disclosure !== false,
      applicationEnvironment: result.application_environment,
      policyRequirements: result.policy_requirements ?? policyRequirements,
    })

    // Set update data
    setCurrentUpdate(result)
  }

  /**
   * Parse server response into a failure type
   * @param {*} linkUpdateResponse
   * @param {*} linkUpdateResult
   * @returns
   */
  const parseLoginFailureType = (linkUpdateResponse, linkUpdateResult) => {
    // Check blocked
    if (linkUpdateResponse.status == 503) return 'blocked'

    // Check locked
    if (linkUpdateResponse.headers.get('Login-Status') == 'locked')
      return 'locked'

    // Check creds
    if (
      linkUpdateResult.message ===
        'Please verify your credentials and try again.' ||
      linkUpdateResult.message ===
        "The credentials you entered don't match a valid sandbox credential set." +
          " Note that live carrier credentials won't work in the sandbox environment."
    )
      return 'invalid_creds'

    // Default
    return 'other'
  }

  /**
   * Process the update response
   */
  const handleUpdate = async ({ response, result }) => {
    // Check for an unsuccessful response
    if (!response.ok || result?.message) {
      // Specific step check
      switch (currentUpdate?.step_type) {
        case 'login':
          // Automatic recovery
          if (currentUpdate._embedded?.provider?.actions?.automatic_recovery === true) {
            
            // Increment login attempts
            loginAttemptsRef.current = loginAttemptsRef.current + 1

            // Handle max login attempts
            if (loginAttemptsRef.current >= 2) {
              // Send analytics event
              analytics.capture('LoginAttemptsMaxed', {
                message:
                  'The user has attempted to login 3 times unsuccessfully',
                provider: currentUpdate._embedded.provider.name,
              })

              // Prompt document upload
              setCurrentUpdate({
                ...currentUpdate,
                message: null,
                show_upload_prompt: true,
              })
              return
            }

            // Handle specific login failures
            if ([500, 503, 504, 400].includes(response.status)) {
              // Send analytics event
              analytics.capture('LoginFailed', {
                message: `The login has failed with the status code ${response.status}`,
                provider: currentUpdate._embedded.provider.name,
              })

              // Prompt document upload
              setCurrentUpdate({
                ...currentUpdate,
                message: null,
                show_upload_prompt: true,
              })
              return
            }
          }

          // Send analytics event
          analytics.capture('LoginCredentialsUnsuccessful', {
            message: result.message,
            provider: currentUpdate._embedded.provider.name,
            failureType: parseLoginFailureType(response, result),
          })

          break
      }
    }

    // Check individual status codes
    switch (response.status) {
      case 206: // Partial policy returned
      case 200:
        // Trigger analytic event
        if (
          result.message ===
          'The code you entered is incorrect. Please try again.'
        ) {
          analytics.capture('MFACodeUnsuccessful')
        }

        // Check for any step specific events to trigger
        switch (result?.step_type) {
          case 'complete':
            // Trigger analytic event
            analytics.capture('PoliciesReturnedFromAPI', {
              policyIds: result._embedded.policies.map((policy) => policy.id),
            })

            // Clear cookies
            clearSession()

            // Push success message
            pushSuccess(result)
            break
        }

        // Update link
        setCurrentUpdate(result)

        break

      case 422:
        // Show custom error
        setLocalError('Could not process your request.')
        break

      case 408:
        // Show custom error
        setLocalError('Session expired. Restarting...')
        reset()
        break

      case 404:
        // Clear invalid linkId
        setCurrentSession({
          integrationKey: currentSession.integrationKey,
        })

        // Show custom error
        throwGlobalError(
          'The link you are attempting to interact with does not exist.',
          'INVALID_LINK_ID'
        )
        break

      case 400:
        // Show error from server or fall back to generic
        throwGlobalError(
          parseServerMessage(
            result.message || 'Something went wrong.',
            'LINK_STEP_FAILED'
          )
        )
        break

      case 500:
        // Show error from server or fall back to generic
        throwGlobalError('Something went wrong.', 'INTERNAL_SERVER_ERROR')
        break

      case 503:
        // Show error from server or fall back to generic
        throwGlobalError(
          parseServerMessage(
            result.message || 'Something went wrong.',
            'LINK_STEP_FAILED'
          )
        )
        break
    }
  }

  /**
   * Send payload and request next link update
   */
  const action = async (payload, showLoading = true, linkId, triggerStepChange = true) => {
    setTriggerStepChange(triggerStepChange)
    try {
      // Start loading
      if (showLoading) setGlobalLoading(true)

      // Make request
      const request = await makePostRequest({
        endpoint: `/v1/links/${
          linkId ? linkId : currentSession.linkId
        }/updates`,
        payload: {
          ...payload,
          state_id: payload?.state_id || currentUpdate?.state_id,
        },
      });

      // Handle update
      await handleUpdate(request)
    } catch (error) {
      if (error === 'request_timeout') {
        // Automatic recovery
        if (currentUpdate._embedded?.provider?.actions?.automatic_recovery === true) {
           // Send analytics event
           analytics.capture('LoginFailed', {
            message: `The login has failed with the status code 504`,
            provider: currentUpdate._embedded.provider.name,
          })

          // Prompt document upload
          setCurrentUpdate({
            ...currentUpdate,
            message: null,
            show_upload_prompt: true,
          })
          return
        }
      }
 
      // TODO: Add more specific error messages
      throwGlobalError('Something went wrong.')
    } finally {
      //Stop loading
      if (showLoading) setGlobalLoading(false)
    }
  }

  /**
   * Filter providers from search term
   */
  const searchProviders = async (searchTerm, signal) => {
    try {
      // Send search query up to server and get results
      const providersQueryResponse = await fetch(
        `${apiBase}/v1/links/${currentSession.linkId}/providers?q=${searchTerm}`,
        {
          signal,
          headers: {
            Authorization: getBearerToken(),
          },
        }
      )

      // Parse results
      const providersQueryResult = await providersQueryResponse.json()
      return providersQueryResult._embedded.providers
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Submit user submission from missing provider page
   */
  const submitMissingProvider = async (payload) => {
    try {
      return await makePostRequest({
        endpoint: `/v1/missing_providers`,
        ignoreResponse: true,
        payload,
      })
    } catch (error) {
      console.log(error)
      throwGlobalError(
        'Could not submit your provider details.',
        'UNSUPPORTED_PROVIDER'
      )
    }
  }

  /**
   * Reset and restart link
   * This is handled by the server response
   */
  const cancel = async () => {
    await action({ action: 'cancel' })
  }

  /**
   * Go to previous state
   * This is handled by the server response
   */
  const back = async () => {
    await action({ action: 'back' })
  }

  /**
   * Clear session
   */
  const clearSession = () => {
    destroyCookie(null, 'linkId', { path: '/' })
    destroyCookie(null, 'sessionId', { path: '/' })
    destroyCookie(null, 'stateId', { path: '/' })
  }

  /**
   * Reset link, session and initialize from scratch
   */
  const reset = (reinitialize) => {
    // Clear cookies
    clearSession()

    // Clear global states
    setGlobalError('')
    setGlobalLoading(false)

    // Reset initialized
    setInitialized(false)

    if (reinitialize) {
      setCurrentSession({
        integrationKey: currentSession.integrationKey,
        linkId: currentSession.linkId,
        sessionId: null,
        reset: true,
      })
    }
  }

  const providerValue = {
    action,
    handleUpdate,
    initialize,
    cancel,
    back,
    reset,
    submitMissingProvider,
    searchProviders,
    pushInit,
    pushSuccess,
    pushLinkError,
    pushStepChange,
    pushIneligibleLink,
  }

  return (
    <AccessContext.Provider value={providerValue}>
      {children}
    </AccessContext.Provider>
  )
}
