import { useApp } from 'providers/app'
import PropTypes from 'prop-types'

/**
 * Generic spinner to indicate loading
 */
function Spinner({ size = 80 }) {
  // Access brand
  const { primaryTextStyle } = useApp()

  return (
    <svg
      viewBox="0 0 38 38"
      stroke="currentColor"
      width={size}
      role="progressbar"
      className="mx-auto text-purple-600"
      style={primaryTextStyle}
    >
      <g
        transform="translate(1 1)"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity="0.5" cx="18" cy="18" r="18"></circle>
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
      </g>
    </svg>
  )
}

Spinner.propTypes = {
  size: PropTypes.number,
}

export default Spinner
