import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import EmbedLayout from 'components/layouts/EmbedLayout'
import Alert from 'components/global/Alert'
import { useRequests } from 'providers/requests'
import { currentSessionState } from 'atoms/Link'
import { parseServerMessage } from 'providers/utils'
import FullpageNotice from 'components/global/FullpageNotice'

export default function PolicyConfirmation({ uploadError, setShowConfirmationScreen }) {
  // Shared state
  const currentSession = useRecoilValue(currentSessionState)

  // Local state
  const [submitting, setSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [complete, setComplete] = useState(false)
  const [error, setError] = useState('')

  // Access requests
  const { makePostRequest, pushSuccess, pushStepChange } = useRequests()

  useEffect(() => {
    pushStepChange('policy_confirmation');
    return () => {
      pushStepChange('document_upload');
    }
  }, []);

  const validateForm = (fieldValues) => {
    let errors = {}

    if (!fieldValues.insured_name) {
      errors.insured_name = "Insured Name is required"
    }

    if (!fieldValues.policy_number) {
      errors.policy_number = "Policy Number is required"
    }

    if (!fieldValues.start_date) {
      errors.start_date = "Start Date is required"
    } else if (!/^\d{2}\/\d{2}\/\d{4}$/.test(fieldValues.start_date)) {
      errors.start_date = "Start Date must be in MM/DD/YYYY format"
    }

    if (!fieldValues.liability_limit) {
      errors.liability_limit = "Liability Limit is required"
    } else if (isNaN(fieldValues.liability_limit)) {
      errors.liability_limit = "Liability Limit must be a number"
    }

    if (!fieldValues.address) {
      errors.address = "Address is required"
    }

    if (!fieldValues.city) {
      errors.city = "City is required"
    }

    if (!fieldValues.state) {
      errors.state = "State is required"
    }

    if (!fieldValues['postal_code']) {
      errors.postal_code = "Postal Code is required"
    } else if (!/^\d{5}(-\d{4})?$/.test(fieldValues.postal_code)) {
      errors.postal_code = "Postal Code must be a 5-digit number"
    }

    return errors
  }

  const formatDateString = (dateString) => {
    const [month, day, year] = dateString.split('/')
    return `${year}-${month}-${day}`
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setFormErrors({})
    
    const formData = new FormData(event.target)
    const fieldValues = {}
    formData.forEach((value, key) => {
      fieldValues[key] = value
    })

    const errors = validateForm(fieldValues)
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    fieldValues.start_date = formatDateString(fieldValues.start_date)

    try {
      // Begin loading state
      setSubmitting(true)
      setError('')

      const payload = {
        type:  'renter',
        number: fieldValues['policy_number'],
        policyholders: [{
          type: 'person',
          name: fieldValues['insured_name']
        }],
        inception_date: fieldValues['start_date'],
        locations: [
          {
            address: {
              line1: fieldValues['address'],
              city: fieldValues['city'],
              state: fieldValues['state'],
              postal_code: fieldValues['postal_code'],
            },
            coverages: [
              {
                name: "personal_liability",
                limits: {
                  "occurrence": new Number(fieldValues['liability_limit'])
                }
              }
            ]
          }
        ]
      };

      const { response, result } = await makePostRequest({
        endpoint: `/v1/links/${currentSession.linkId}`,
        payload,
      })

      if (response.ok) {
        pushStepChange('complete');
        pushSuccess(result)
        setComplete(true)
      } else {
        setError(
          `Failed to send policy information - ${parseServerMessage(response.result.message)}`
        )
      }

    } catch (error) {
      // End loading state
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  }

  // Handle input mask for date
  const handleDateInput = (event) => {
    const value = event.target.value.replace(/\D/g, '')
    const month = value.substring(0, 2)
    const day = value.substring(2, 4)
    const year = value.substring(4, 8)
    let formattedDate = month
    if (value.length > 2) {
      formattedDate += '/' + day
    }
    if (value.length > 4) {
      formattedDate += '/' + year
    }
    event.target.value = formattedDate
  }

  if (complete) {
    return (
      <EmbedLayout title="Confirm Your Policy Information">
        <FullpageNotice
          type="success"
          title="Policy Information Sent"
          message="Your policy information has been stored."
        />
      </EmbedLayout>
    )
  }

  // Render screen
  return (
    <EmbedLayout title="Confirm Your Policy Information">
      {uploadError && (
        <Alert dismissible type="error">
          {uploadError}
        </Alert>
      )}

      {error && (
        <Alert dismissible type="error">
          {error}
        </Alert>
      )}

      <form className="flex flex-1 flex-col space-y-4 lg:space-y-6" onSubmit={onSubmit}>
        <div className="flex-1">
          <div className="space-y-6 pt-4 sm:space-y-4">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label
                  htmlFor="insured_name"
                  className="text-md block capitalize text-gray-700"
                >
                  Insured Name
                </label>
                <div className="mt-2">
                  <input
                    id="insured_name"
                    name="insured_name"
                    type="text"
                    className="rounded py-3 w-full focus:ring-purple-600 focus:border-purple-600 w-full  border-gray-200"
                  />
                  {formErrors.insured_name && <p className="text-red-500">{formErrors.insured_name}</p>}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="policy_number"
                  className="text-md block capitalize text-gray-700"
                >
                  Policy number
                </label>
                <div className="mt-2">
                  <input
                    id="policy_number"
                    name="policy_number"
                    type="text"
                    className="w-full rounded border-gray-200 py-3 focus:border-purple-600  focus:ring-purple-600"
                  />
                  {formErrors.policy_number && <p className="text-red-500">{formErrors.policy_number}</p>}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="start_date"
                  className="text-md block capitalize text-gray-700"
                >
                  Start date
                </label>
                <div className="mt-2">
                  <input
                    id="start_date"
                    name="start_date"
                    type="text"
                    autoComplete="start_date"
                    className="rounded py-3 w-full focus:ring-purple-600 focus:border-purple-600 w-full  border-gray-200"
                    onInput={handleDateInput}
                    maxLength="10"
                    placeholder="MM/DD/YYYY"
                  />
                  {formErrors.start_date && <p className="text-red-500">{formErrors.start_date}</p>}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="liability_limit"
                  className="text-md block capitalize text-gray-700"
                >
                  Liability Limit
                </label>
                <div className="mt-2">
                  <input
                    id="liability_limit"
                    name="liability_limit"
                    type="text"
                    className="rounded py-3 w-full focus:ring-purple-600 focus:border-purple-600 w-full  border-gray-200"
                  />
                  {formErrors.liability_limit && <p className="text-red-500">{formErrors.liability_limit}</p>}
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="address"
                  className="text-md block capitalize text-gray-700"
                >
                  Covered Address
                </label>
                <div className="mt-2">
                  <input
                    id="address"
                    name="address"
                    type="text"
                    className="w-full rounded border-gray-200 py-3 focus:border-purple-600  focus:ring-purple-600"
                  />
                  {formErrors.address && <p className="text-red-500">{formErrors.address}</p>}
                </div>
              </div>

              <div className="sm:col-span-3 sm:col-start-1">
                <label
                  htmlFor="city"
                  className="text-md block capitalize text-gray-700"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    id="city"
                    name="city"
                    type="text"
                    className="w-full rounded border-gray-200 py-3 focus:border-purple-600  focus:ring-purple-600"
                  />
                  {formErrors.city && <p className="text-red-500">{formErrors.city}</p>}
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="state"
                  className="text-md block capitalize text-gray-700"
                >
                  State
                </label>
                <div className="mt-2">
                  <select
                    id="state"
                    name="state"
                    defaultValue="AL"
                    className="rounded py-3 w-full focus:ring-purple-600 focus:border-purple-600 border-gray-200"
                  >
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                  </select>
                  {formErrors.state && <p className="text-red-500">{formErrors.state}</p>}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal_code"
                  className="text-md block capitalize text-gray-700"
                >
                  ZIP / Postal code
                </label>
                <div className="mt-2">
                  <input
                    id="postal_code"
                    name="postal_code"
                    type="text"
                    autoComplete="postal-code"
                    className="w-full rounded border-gray-200 py-3 focus:border-purple-600  focus:ring-purple-600"
                  />
                  {formErrors.postal_code && <p className="text-red-500">{formErrors.postal_code}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse gap-4 pt-4 md:flex-row md:items-center md:justify-between">
          <div className="mb-6 text-center md:mb-0 md:text-left"></div>
          <div className="flex flex-col-reverse gap-2 md:flex-row">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded border border-gray-500 bg-white px-6 py-2 text-center font-medium text-gray-800 transition duration-200 hover:border-gray-300 hover:bg-gray-50"
              onClick={() => setShowConfirmationScreen(false)}
            >
              <span>Back</span>
            </button>
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded border border-purple bg-purple px-6 py-2 text-center  font-medium text-white transition duration-200 hover:border-purple-light hover:bg-purple-light"
              disabled={submitting}
            >
              <span>Continue</span>
            </button>
          </div>
        </div>
      </form>
    </EmbedLayout>
  )
}
