import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useApp } from 'providers/app'
import { useRecoilValue } from "recoil";
import { apiBaseState } from "atoms/Link";

/**
 * SVG logo
 * @param {introduction} Introduction controls whether to render the 'Powered by' notice
 * @param {center} Center controls whether to centralise the logo and introduction text
 */
function Logo({ introduction, center, fill }) {
  // Access brand
  const { brand } = useApp()
  const apiBase = useRecoilValue(apiBaseState)

  const [imageError, setImageError] = useState(false)

  const router = useRouter()
  const { integrationKey } = router.query

  // Check if we need to hide the logo
  if (brand?.hide_logo || imageError) return false

  // Check if we need to show custom logo
  if (brand?.logo)
    return (
      <div className="inline-flex overflow-hidden rounded">
        <img
          onError={() => {
            setImageError(true)
            Sentry.captureException(
              `Brand logo failed to load on integration ${integrationKey}`
            )
          }}
          src={brand.logo.startsWith('/') ? apiBase + brand.logo : brand.logo}
          className={`${fill ? 'flex-1' : 'h-6 w-auto'} object-cover`}
        />
      </div>
    )

  let textAlignmentClass = center ? 'text-center' : 'text-center sm:text-right'
  return (
    <div className="flex items-end space-x-2">
      {introduction && (
        <div className={`text-xs text-gray-400 ${textAlignmentClass}`}>
          Powered by
        </div>
      )}
      <div className="flex items-center justify-end">
        <a
          target="_blank"
          href="https://www.covie.com/solutions/agents?utm_source=embed"
          rel="noreferrer"
        >
          <img
            id="logo"
            src="/images/logo.svg"
            alt="Covie logo"
            className={`${introduction ? 'mb-0.5 h-6' : 'h-10'}`}
          />
        </a>
      </div>
    </div>
  )
}

Logo.propTypes = {
  introduction: PropTypes.bool,
  center: PropTypes.bool,
  fil: PropTypes.bool,
}

export default Logo
