import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { XIcon } from '@heroicons/react/solid'
import { currentUpdateState } from 'atoms/Link'
import { useRequests } from 'providers/requests'
import { localErrorState } from 'atoms/Form'
import Spinner from 'components/global/Spinner'
import Logo from 'components/global/Logo'
import Container from 'components/global/Container'
import Alert from 'components/global/Alert'
import { useState } from 'react'
import { parseServerMessage } from "providers/utils";

const CurrentProvider = () => {
  // Shared state
  const currentUpdate = useRecoilValue(currentUpdateState)

  // Get provider
  const provider = currentUpdate?._embedded?.provider || null
  if (!provider || typeof provider !== 'object') return false

  // Store key data
  const image = provider._links.image.href
  const name = provider.name
  const website = provider._links.website?.href || '#'

  // Render
  return (
    <div className="flex items-center">
      <Link
        href={website}
        passHref
        className="provider-logo-link opacity-100 duration-150 hover:opacity-75">

        <img
          src={image}
          alt={name}
          className="h-10 w-32 object-contain object-left"
        />

      </Link>
    </div>
  );
}

export default function EmbedLayout({ title, children, isLoading }) {
  // Shared state
  const [localError, setLocalError] = useRecoilState(localErrorState)
  const currentUpdate = useRecoilValue(currentUpdateState)

  // Local state
  const [hideMessage, setHideMessage] = useState(false)

  // Access router
  const router = useRouter()

  // Get lightbox from url
  const { isLightbox } = router.query

  // Access close
  const { pushClose } = useRequests()

  return (
    <div className="flex h-full flex-1 flex-col">
      {/* Topbar */}
      <div
        className={`border-b border-gray-200 text-gray-500 ${
          isLightbox ? 'bg-gray-50' : ''
        }`}
      >
        <Container>
          <div className="flex items-center justify-between">
            {/* Left side */}
            <div className={`flex space-x-2 p-2 sm:p-4`}>
              {/* Title & step */}
              {title && (
                <span className="font-medium text-gray-800 sm:text-lg">
                  {title}
                </span>
              )}
            </div>

            {/* Right side */}
            <div className="flex items-center space-x-2">
              {/* Loading state */}
              {isLoading && (
                <div className="flex h-full items-center justify-center">
                  <Spinner size={24} />
                </div>
              )}

              {/* Lightbox close */}
              {isLightbox && (
                <button
                  id="lightbox-close-button"
                  type="button"
                  onClick={pushClose}
                  className="flex h-full items-center justify-center border-l border-gray-200 bg-gray-50 p-4 duration-150 hover:bg-gray-100"
                >
                  <XIcon className="h-6 w-6" />
                </button>
              )}
            </div>
          </div>
        </Container>
      </div>

      {/* Body */}
      <div className="flex flex-1 items-stretch overflow-y-auto">
        <Container>
          <div className="relative flex min-h-full flex-col space-y-3 p-3 sm:space-y-4 sm:p-4">
            {/* Local error */}
            {localError && (
              <Alert
                onClose={() => {
                  setLocalError(null)
                }}
                type="error"
              >
                {localError}
              </Alert>
            )}

            {/* Server message */}
            {currentUpdate?.message && !hideMessage && (
              <Alert
                onClose={() => {
                  setHideMessage(true)
                }}
                type="error"
              >
                {parseServerMessage(currentUpdate.message)}
              </Alert>
            )}

            {children}
          </div>
        </Container>
      </div>

      {/* Footer */}
      <div
        className={`border-t border-gray-200 ${isLightbox ? 'bg-gray-50' : ''}`}
      >
        <Container>
          <div className="flex items-center justify-between p-2 sm:px-4">
            {/* Left side */}
            <div>
              <CurrentProvider />
            </div>

            {/* Right side */}
            <div className="flex h-10 items-center">
              <Logo introduction />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
