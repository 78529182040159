import { useState } from 'react'
import { useAnalytics } from 'providers/analytics'
import { useTimeout } from 'providers/utils'
import { PRIVACY_URL } from 'data/links'
import PropTypes from 'prop-types'

function ExternalLink({ id = '', className, children, href, props }) {
  // Local state
  const [loading, setLoading] = useState(false)
  const [duration, setDuration] = useState(null)
  useTimeout(() => {
    setLoading(false)
    setDuration(null)
  }, duration)

  // Access analytics
  const analytics = useAnalytics()

  /**
   * Handle click & loading state to button
   */
  const handleClick = (e) => {
    // Check if link clicked is privacy policy
    if (href === PRIVACY_URL) {
      // Trigger analytic event
      analytics.capture('PrivacyPolicyLinkClicked')
    }

    // Open link
    window.open(href, '_blank')

    // Show debounce effect on tablets and below
    if (window.screen.width < 1024) {
      setLoading(true)
      setDuration(3000)
    }
  }

  return (
    <button
      id={id}
      type="button"
      href={href}
      {...props}
      className={className}
      onClick={handleClick}
      disabled={loading}
    >
      {children({
        loading,
      })}
    </button>
  )
}

// Define prop types
ExternalLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  props: PropTypes.object,
}

export default ExternalLink
