import PropTypes from 'prop-types'
import { useApp } from 'providers/app'
import {
  ExclamationIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/solid'
import { useState } from 'react'

function Alert({ children, heading, onClose, type, dismissible }) {
  // Access brand
  const { primaryBackgroundStyle } = useApp()

  // Local state
  const [show, setShow] = useState(true)

  // Define colour outputs
  let bgColor, textColor, closeColor, Icon
  switch (type) {
    case 'error':
      closeColor = 'bg-red-800 bg-opacity-10'
      bgColor = 'bg-red-50'
      textColor = 'text-red-500'
      Icon = XCircleIcon
      break
    case 'notice':
      closeColor = 'bg-yellow-800 bg-opacity-10'
      bgColor = 'bg-yellow-50'
      textColor = 'text-yellow-500'
      Icon = ExclamationCircleIcon
      break
    case 'success':
      closeColor = 'bg-green-800 bg-opacity-10'
      bgColor = 'bg-green-50'
      textColor = 'text-green-500'
      Icon = CheckCircleIcon
      break
    default:
      closeColor = 'bg-purple-800 bg-opacity-10'
      bgColor = 'bg-purple-50'
      textColor = 'text-purple-500'
      Icon = ExclamationIcon
  }

  /**
   * Handle close event
   */
  const handleClose = () => {
    setShow(false)
    if (onClose) onClose()
  }

  const multiline = heading && children
  if (!show) return false
  return (
    <div
      className={`rounded-md ${bgColor} ${textColor} flex justify-between ${
        multiline ? 'items-start' : 'items-center'
      }`}
      style={!type ? primaryBackgroundStyle : null}
    >
      {/* Left side */}
      <div
        className={`flex space-x-3 p-3 ${
          multiline ? 'items-start' : 'items-center'
        }`}
      >
        <div className="flex shrink-0 items-center">
          <div>
            <Icon className="h-5 w-5" />
          </div>
        </div>
        <div className="flex-1 space-y-0.5">
          {heading && <h3 className={`text-sm font-medium`}>{heading}</h3>}
          {children && (
            <div className={`text-sm ${multiline ? 'text-opacity-70' : ''}`}>
              {children}
            </div>
          )}
        </div>
      </div>

      {/* Right side */}
      {(onClose || dismissible) && (
        <div className="flex items-center p-3">
          <button
            onClick={handleClose}
            className={`shrink-0 rounded p-1 duration-150 ${closeColor} hover:bg-opacity-20`}
          >
            <XIcon className="h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  )
}

// Define prop types
Alert.propTypes = {
  heading: PropTypes.string,
  type: PropTypes.oneOf(['error', 'default', 'notice', 'success']),
  dismissible: PropTypes.bool,
}

export default Alert
